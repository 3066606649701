<template>
  <div>
    <master-component :config="config">
      <!-- <template v-slot:table-oas="{ item }">
        <div
          v-for="(oa,index) in item.oas"
          :key="index"
        >
          {{ oa.text }}
        </div>
        <span style="visibility:hidden">.<span style="display:none">for workaround </span></span>

      </template> -->

    </master-component>
  </div>
</template>


<script>
import {
  getRoles,
  getNamePrefixs,
  getBranches,
  getCustomers,
} from "./../js/dropdown-service";
import {
  UserRole,
  InputType,
  Usr,
  DialogSize,
  Customer,
} from "./../js/constants";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import { getToken, isAdmin, isManagement } from "../js/authorization";

export default {
  data() {
    return {};
  },
  created() {
    const config = this.config;
  },
  computed: {
    config() {
      return {
        name: "master-employee",
        shortName: "พนักงาน",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    urlSection() {
      let searchUrl = "/employee/search";
      let getUrl = "/employee";
      let addUrl = "/employee";
      let editUrl = "/employee";
      let removeUrl = "/employee";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "firstName",
              text: "ชื่อ",
              type: InputType.text,
            },
            {
              name: "lastName",
              text: "สกุล",
              type: InputType.text,
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
            },
          ],
          [
            {
              name: "role",
              text: "Role",
              type: InputType.dropdown,
              data: {
                promise: getRoles().then((res) => {
                  res.items = res.items.filter((x) => x.id !== UserRole.LAWYER);
                  return res;
                }),
                url: "",
              },
            },
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              data: {
                promise: getCustomers(),
              },
            },
            {
              name: "oa",
              text: "OA",
              type: InputType.dropdownRelate,
              parent: "customer",
              data: {
                url: "/dropdown/branches?includeCustomerText=false&customerId=",
              },
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN],
        edit: [UserRole.ADMIN],
        remove: [UserRole.ADMIN],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "firstName",
            text: "ชื่อ",
            style: {
              width: "120px",
            },
          },
          {
            name: "lastName",
            text: "สกุล",
            style: {
              width: "120px",
            },
          },
          {
            name: "email",
            text: "Email",
          },
          {
            name: "role.text",
            text: "Role",
            style: {
              width: "60px",
            },
          },
          {
            name: "customer.text",
            text: "ลูกค้า",
            style: {
              width: "50px",
            },
          },
          {
            name: "oa.text",
            text: "OA",
            style: {
              width: "350px",
            },
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              text: "Reset password",
              type: InputType.button,
              columnClass: "text-right",
              showIf: ({ model }) => {
                let result = false;

                let token = getToken();
                let condition1 = model.id ? true : false;
                let condition2 = token.username == Usr.Support;
                let condition3 = isAdmin();
                let others = [condition2, condition3];

                if (condition1 && others.some((x) => x == true)) {
                  result = true;
                }

                return result;
              },
              buttonConfig: {
                class: "primary mb-3",
                click: ({ model }) => {
                  if (!confirm("คุณต้องการรีเซ็ตรหัสผ่านใช่หรือไม่ ?")) {
                    return;
                  }

                  let url = `/account/resetPassword/employee/${model.id}`;
                  // url = getFakeUrl(fakeAbbr.resetPassword) + url;

                  this.$http.post(url).then((res) => {
                    let message = `กรุณาส่งรหัสผ่านนี้ให้ user : ${res}`;
                    alert(message);
                  });
                },
              },
            },
          ],
          [
            {
              name: "namePrefix",
              text: "คำนำหน้า",
              type: InputType.dropdown,
              data: {
                promise: getNamePrefixs(),
                url: "",
              },
              rule: {
                required: true,
              },
            },
            {
              name: "firstName",
              text: "ชื่อ",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            {
              name: "lastName",
              text: "สกุล",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
          ],
          [
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                required: true,
                //email: true
              },
            },
            {
              name: "role",
              text: "Role",
              type: InputType.dropdown,
              data: {
                promise: getRoles().then((res) => {
                  res.items = res.items.filter((x) => x.id !== UserRole.LAWYER);
                  return res;
                }),
                url: "",
              },
              rule: {
                required: true,
              },
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              data: {
                promise: getCustomers(),
              },
            },
            {
              name: "oa",
              text: "OA",
              type: InputType.dropdownRelate,
              parent: "customer",
              data: {
                url: "/dropdown/branches?includeCustomerText=false&customerId=",
              },
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              type: "section",
              text: "Credential",
            },
          ],
          [
            {
              name: "userName",
              text: "User Name",
              type: InputType.text,
              disabledWhenEdit: true,
              rule: {
                required: true,
                min: 4,
                max: 20,
              },
            },
            {
              name: "password",
              text: "Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                required: true,
                regex: "",
                min: 7,
                max: 20,
              },
            },
            {
              name: "confirmPasword",
              text: "Confirm Password",
              type: InputType.password,
              hideWhenEdit: true,
              rule: {
                confirmed: "password",
                required: true,
              },
            },
            {
              type: InputType.offset,
            },
          ],
          // [
          //   {
          //     type: "section",
          //     text: "Line Connect",
          //   },
          // ],
          // [
          //   {
          //     name: "lineConnect",
          //     type: InputType.lineConnect,
          //   },
          // ],
        ],
      };
    },
  },
};
</script>
